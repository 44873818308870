// @ts-check
'use client'

//Note this sidebar was retrieved from https://chakra-template.vercel.app

import React, { useState, } from 'react';

import {chooseIcon} from '../../CustomTheme'
import {FiMenu,
        FiArrowDown,
} from 'react-icons/fi'
import {
  Box,
  BoxProps,
  Center,
  CloseButton,
  Collapse,
  Drawer,
  DrawerContent,
  Flex,
  FlexProps,
  HStack,
  Icon,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';
import { IconType } from 'react-icons';

import {
  NavLink
} from "react-router-dom";
import { FetchPageStructureData } from '../../services/cmsAPI.js';

type Props = {
  title: string;
  children?: JSX.Element;
  logo: string;
};

interface LinkItemProps {
  name: string
  icon: any
}

interface NavItemProps extends FlexProps {
  icon: any
  children: React.ReactNode
}

interface MobileProps extends FlexProps {
  onOpen: () => void;
  logo: string;
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
  logo: string;
}

interface SidebarItemProps extends BoxProps {
  attributes: { PageTitle: string, URLsegment: string, LinksToAPage: boolean, PageIcon: { Icon: string } | null };
  subsections: Array<any>;
  index: number;
  onClick: any;
}

const SidebarItem =({attributes, subsections, index, onClick, ...rest }: SidebarItemProps)=>{
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  //Define the behaviour of appearing subpages in the sidebar 
  const handleMouseEnter = () => {
    setDropdownVisible(true);
  };
  const handleMouseLeave = () => {
    setDropdownVisible(false);
  };

  let icon = attributes.PageIcon != null ? attributes.PageIcon.Icon : ""


  return(
  <Box 
  onMouseEnter={handleMouseEnter}
  onMouseLeave={handleMouseLeave}
  onTouchStart={handleMouseEnter}>
    {attributes.LinksToAPage
    ?
    <NavLink to = {'/'.concat(attributes.URLsegment)} onClick={onClick}>
        <NavItem textColor={'white'} icon = {chooseIcon(icon)}>
            {attributes.PageTitle}
            {subsections[index].length!==0 ?
              <><Box flex='1'/><FiArrowDown/></>
              :
              <></>
            }
        </NavItem>
    </NavLink>
    :
    <NavItem textColor={'white'} icon = {chooseIcon(icon)}>
            {attributes.PageTitle}
            {subsections[index].length!==0 ?
              <><Box flex='1'/><FiArrowDown/></>:
              <></>
            }
      </NavItem>
    }
    {(subsections.length !==0 && subsections!=null)?
    <Collapse in={isDropdownVisible}>{
          subsections[index].map((e: any,i: number) =>(
            <NavLink to = {'/'.concat(attributes.URLsegment).concat('/').concat(e.attributes.URLsegment)} onClick={onClick}> 
                <NavItem textColor={'white'} icon = {e.attributes.PageIcon !== null ? chooseIcon(e.attributes.PageIcon.Icon) : chooseIcon("")} maxW='75%' marginLeft='25%'>
                  {e.attributes.PageTitle}
                </NavItem>
            </NavLink>
        ))}
      </Collapse>    
    :
    <></>
  }
  </Box>
  )

}

const SidebarContent = ({ logo, onClose, ...rest }: SidebarProps) => {
  const {pages, subsections,URLpages,URLsubsections,loadingMenu} = FetchPageStructureData();
  
  if(!loadingMenu){
  return (
    <Box
      transition="3s ease"
      bg='gray.900'
      borderRight="1px"
      borderRightColor={'gray.200'}
      w={{ base: 'full', md: 60 }}
      pos="fixed"
      h="full"
      {...rest}>
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Center>
          <Image 
          maxH='6dvh'
          maxW='6dvw'
          marginTop='1dvh'
          src= {logo}
          alt='Logo' 
          />
        </Center>
        <CloseButton display={{ base: 'flex', md: 'none' }} color='white' onClick={onClose} />
      </Flex>
      <NavLink to = {'/'} onClick={onClose}>
          <NavItem textColor={'white'} icon = {chooseIcon("Home")}>
              Home
          </NavItem>
      </NavLink>
      <NavLink to = {'/bazuin'} onClick={onClose}>
          <NavItem textColor={'white'} icon = {chooseIcon("Compass")}>
              Den Bazuin
          </NavItem>
      </NavLink>
      {pages.map((item,index) => (
        <SidebarItem attributes = {item.attributes} subsections = {subsections} index={index} onClick={onClose}/>
      ))}
      <NavLink to = {'/contact'} onClick={onClose}>
          <NavItem textColor={'white'} icon = {chooseIcon("Mail")}>
             Leiding & Contact
          </NavItem>
      </NavLink>
    </Box>
  )
  }
  else {
    return(<div></div>)
  }
}

const NavItem = ({ icon, children, ...rest }: NavItemProps) => {
  return (
    <Box
      as="a"
      href="#"
      style={{ textDecoration: 'none' }}
      _focus={{ boxShadow: 'none' }}>
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: 'cyan.400',
          color: 'white',
        }}
        {...rest}>
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: 'white',
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Box>
  )
}

const MobileNav = ({ onOpen, logo, ...rest }: MobileProps) => {
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue('gray.900', 'gray.900')}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.500', 'gray.700')}
      justifyContent={{ base: 'space-between', md: 'flex-end' }}
      {...rest}
      boxShadow='dark-lg' >
      
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu color="white"/>}
      />

      {/*<Text
        display={{ base: 'flex', md: 'none' }}
        fontSize="2xl"
        fontFamily="monospace"
        fontWeight="bold">
        Logo
      </Text>*/}
      <Center>
        <NavLink to = {'/'}>
          <Image 
              maxH='6dvh'
              maxW='6dvw'
              marginTop='1dvh'
              src= {logo}
              alt='Logo' 
              display={{ base: 'flex', md: 'none' }}
              />
        </NavLink>
      </Center>
    
      <HStack spacing={{ base: '0', md: '6' }}>
        <Box boxSize='10'/>
        {/*<IconButton size="lg" variant="ghost" aria-label="open menu" color='white' icon={<FiBell />} />*/}
        <Flex alignItems={'center'}>
          <Menu>
            <MenuButton py={2} transition="all 0.3s" _focus={{ boxShadow: 'none' }}>
              {/*
              <HStack>
                <Avatar
                  size={'sm'}
                  src={
                    'https://images.unsplash.com/photo-1619946794135-5bc917a27793?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9'
                  }
                />
                <VStack
                  display={{ base: 'none', md: 'flex' }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2">
                  <Text fontSize="sm" color="white">Justina Clark</Text>
                  <Text fontSize="xs" color='white'>
                    Admin
                  </Text>
                </VStack>
                <Box display={{ base: 'none', md: 'flex' }}>
                  <FiChevronDown />
                </Box>
              </HStack>
              */}
            </MenuButton>
            <MenuList
              bg={useColorModeValue('white', 'gray.900')}
              borderColor={useColorModeValue('gray.200', 'gray.700')}>
              <MenuItem>Profile</MenuItem>
              <MenuItem>Settings</MenuItem>
              <MenuItem>Billing</MenuItem>
              <MenuDivider />
              <MenuItem>Sign out</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  )
}

const SidebarWithHeader = ({children, logo}: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <Box>
      <SidebarContent logo={logo} onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
      <Box bg='gray.900'>
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full">
        <DrawerContent bg='gray.900' overflow="scroll">
          <SidebarContent logo ={logo} onClose={onClose} />
        </DrawerContent>
      </Drawer>
      </Box>
      {/* mobilenav */}
      <MobileNav logo={logo} onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }}>
        <div>{children}</div>
      </Box>
    </Box>
  )
}

export default SidebarWithHeader
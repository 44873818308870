import BlogPostWithImage from "../Components/Blogpost"
import {BlockMargin,
        PageTitle,
        SubTitle,
        RTFrenderer
} from "./page.js"
import {
    marginsLeft,
    marginsRight,
} from '../CustomTheme';
import {
    Box,
    Center,
    Image,
    Grid,
} from '@chakra-ui/react';
import { root } from '../services/cmsAPI';
import { useEffect } from 'react';
import {useLocation} from 'react-router-dom';


export const NewsOverview = (props) => {
    console.log(props.data)

    return(
        <Center>
            <Grid templateColumns={{base: "repeat(1,1fr)", md: "repeat(2, 1fr)"}} gap={4}>
                    {
                props.data.slice(0,6).map((e,i) => (
                        <BlogPostWithImage title={e.attributes.Title} text = {e.attributes.Article} image = {root.concat(e.attributes.Image.data.attributes.formats.large.url)} date={e.attributes.publishedAt}/>
                    )
                )
                }
            </Grid>
        </Center>
        )
}

export const NewsArticlePage = (props) =>{
    ScrollToTop();
    return(
        <>
        <PageTitle text={"Nieuwsartikel".toUpperCase()}/>  
        <Box marginLeft={marginsLeft} marginRight={marginsRight} bg='yellow.200' rounded='md' boxShadow = 'dark-lg' minH = '80dvh' overflow='hidden'>
                <BlockMargin/>
                <SubTitle text={props.data.attributes.Title}/>
                <BlockMargin/>
                <Center>
                    <Image 
                    maxH='65dvh'
                    maxW='65dvw'
                    marginLeft='5%' 
                    marginRight='5%'
                    src= {root.concat(props.data.attributes.Image.data.attributes.formats.large.url)}
                    alt='Afbeelding' 
                    />
                </Center> 
                <BlockMargin/>
                <Box maxW = '90%' maxH='90%' marginLeft='5%' marginRight='5%'><RTFrenderer content = {props.data.attributes.Article}/></Box>
                <BlockMargin/>
        </Box>
        <BlockMargin/>
        </>
    )
}

function ScrollToTop() {
    const { pathname } = useLocation();
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  
    return null;
  }

export function convertToSlug(text) {
    return (text.toLowerCase().replace(/ /g, "-").replace(/[^\w-]+/g, ""))
  }
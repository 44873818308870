//Route imports
import React from "react"
import SidebarWithHeader from "./Components/Sidebar/sidebar.tsx"
import { Home, } from "./pages/home"
import { Page, } from "./pages/page"
import {Bazuin, } from "./pages/bazuin"
import {Contact, } from "./pages/contact"
import {
  Box,
  ChakraProvider
} from '@chakra-ui/react'
import {
  FetchPageStructureData,
  GetData,
  root
} from "./services/cmsAPI"
import { customTheme } from './CustomTheme'
import {
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom"
import { Fade, Sticky, batch } from "react-scroll-motion"
import { NewsArticlePage, convertToSlug } from "./pages/news.js"

const FadeAnimation =batch(Fade(),Sticky(50,50))

function App() {
    const {pages,subsections,URLpages,isolatedPages,URLisolatedPages,URLsubsections,loadingMenu} = FetchPageStructureData()
    const {data, loading} = GetData("/api/website-logo?populate=*")
    const {data: dataNews, loading: dataLoading} = GetData("/api/news-articles?sort[0]=publishedAt:desc&populate=*")
    
    let MyURLs = []
    let MyElements = []
    let MyArticleURLs = []
    let MyArticles = []
    if (loadingMenu == false && dataLoading==false)
    {
      for (var i = 0; pages[i]; i++){
        MyURLs.push('/'.concat(URLpages[i]))
        MyElements.push(<Page pageData = {pages[i]}/>)
          for (var j=0; subsections[i][j]; j++)
          {
            const e = subsections[i][j];
            MyElements.push(<Page pageData = {e}/>)
            MyURLs.push('/'.concat(URLpages[i]).concat('/').concat(URLsubsections[i][j]))
          }
        }
      for (var i=0; isolatedPages[i];i++)
      {
        MyURLs.push('/'.concat(URLisolatedPages[i]))
        MyElements.push(<Page pageData = {isolatedPages[i]}/>)
      }
      for (var k = 0; dataNews[k]; k++){
        MyArticleURLs.push(convertToSlug(dataNews[k].attributes.Title))
        MyArticles.push(<NewsArticlePage data = {dataNews[k]}/>)
      }
    }

  return (
    (loading||dataLoading)?
    <></>
    :
    <div>
    <ChakraProvider theme={customTheme}>
    <Router position='top' flex="1">
        <SidebarWithHeader logo={root.concat(data.attributes.Logo.data.attributes.formats.small.url)}> 
          <Box minH="100vh" minW ="100%" bg = "primary.800" overscrollBehaviorY="none">
            <Routes>
              <Route exact path = '/' element = {<Home articleData = {dataNews}/>}/>
              <Route exact path = '/bazuin' element = {<Bazuin/>}/>
              <Route exact path = '/contact' element = {<Contact/>}/>
              {
              MyElements.map((e,i) => (
                <Route exact path = {MyURLs[i]} element = {MyElements[i]}/>
                )
              )}
              {
              MyArticleURLs.map((e,i) =>(
                  <Route exact path = {MyArticleURLs[i]} element = {MyArticles[i]}/>
              )
              )
              }
            </Routes>
          </Box>
        </SidebarWithHeader>
      </Router>
   </ChakraProvider>
   </div>

  )
}
export default App;
